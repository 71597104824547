.root {
  width: 100%;
}

.firstTitle {
  color: #ffffff;
  width: 100%;
  font-size: 16px;
}

.firstSubtitle {
  color: #ffffff;
  font-size: 12px;
  margin-top: 4px;
}

.hours {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
}

.title {
  width: 100%;
  color: #202328;
  font-size: 16px;
}
.subtitle {
  color: #202328;
  font-size: 12px;
  margin-top: 4px;
}

.text {
  box-shadow: 0px 0px 10px 0px #0000001a;
  background-color: #fffefe;
  border-radius: 10px;
  margin-bottom: 15px;
  width: 100%;
  padding: 6px 10px;
  min-height: 90px;
}

.yellow {
  background-color: #fff7d4;
}

.first {
  color: white;
  background-color: #6fcf97;
  //   background-color: #00d400;
}

.yellowTitle {
  color: #202328;
  width: 100%;
  font-size: 16px;
}
.yellowSubtitle {
  color: #202328;
  font-size: 12px;
  margin-top: 4px;
}
.cityWrapper {
  display: flex;
  //align-items: center;
  //justify-content: center;
  font-size: 12px;
  font-weight: 400;
  color: #202328;
  padding-top: 4px;
}
